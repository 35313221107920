import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { LoginButton } from "../../buttons/login-button";
import { LogoutButton } from "../../buttons/logout-button";
import { SignupButton } from "../../buttons/signup-button";
import {SignupButtonMobile} from "../../buttons/signup-button-mobile";
import {LoginButtonMobile} from "../../buttons/login-button-mobile";
import {LogoutButtonMobile} from "../../buttons/logout-button-mobile";
import Ticker from "../../Ticker";

export const MobileNavBarButtons = () => {
  const { user, isAuthenticated } = useAuth0();

  return (
    <div className="mobile-nav-bar__buttons">
      {!isAuthenticated && (
        <>
          <SignupButtonMobile />
          <LoginButtonMobile />
        </>
      )}
      {isAuthenticated && (
        <>
          {/*<LogoutButtonMobile />*/}
          <div style={{ textAlign: "center" }}>

            <p>Logged as {user.email}&nbsp;&nbsp;&nbsp;Build: FRV-2.1.0</p>
          </div>
        </>
      )}
    </div>
  );
};
