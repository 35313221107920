import React from "react";
import {NavBar} from "./navigation/desktop/nav-bar";
import {MobileNavBar} from "./navigation/mobile/mobile-nav-bar";
import {PageFooterMobile} from "./page-footer-mobile";
import Dashboard from "../pages/Dashboard";

export const PageLayoutMobile = ({children}) => {
    return (
        <div className="page-layout">
            {/*<NavBar/>*/}
            <MobileNavBar/>
            <div className="page-layout__content">{children}</div>
            <PageFooterMobile/>
        </div>
    );
};
