import React, {Fragment} from "react";
import {LaneData} from "../components/LaneData";
import {PageLayout} from "../components/page-layout";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import {TopLanes} from "../components/TopLanes";
import Profile from "./Profile";
import {RateTable} from "./RateTable";
import Ticker from "../components/Ticker";
import {CitySearch} from "../components/CitySearch";
import {Favorites} from "./Favorites";
import {Insights} from "./Insights";
import FileUploadProcessor from "./lane-data-file-upload";
import Administration from "./Administration";
import {FacebookEmbed, InstagramEmbed, LinkedInEmbed, XEmbed, YouTubeEmbed} from "react-social-media-embed";
import {DailyLaneDataAnalytics} from "../components/DailyLaneDataAnalytics";
import {GlobalConfig} from "../components/GlobalConfig";

// import TwitterLogo from "../assets/images/social/icons8-twitter.gif";
// import CompanyLogo from "../assets/images/farelanes-logo-gold.png";

import { SocialIcon } from 'react-social-icons'

const Dashboard = () => (
        <Fragment>

            <div className="container-fluid">
                <hr className="hr"/>
                <div className="container-fluid scrollable-div">
                    <div><h3>The Farelanes Report</h3></div>
                    <h6>Freight Pricing Made Simple: You Can't Trust Your Pricing Without Trustworthy Data</h6>
                    <p className="rd-text">The famed “Meet the Parents” movie involves a circle of trust, in which one
                        of the main
                        characters attempts to win over his father-in-law and make it inside the circle. But trust
                        isn’t only ....<a
                            className="read-external-article"
                            target="_blank"
                            rel="noreferrer"
                            href='https://farelanes.com/farelanes-report/'>
                            Read
                            article

                        </a></p>


                    <h5>Finding the Best Freight Rates Shouldn’t Require a Crystal Ball: Three Strategies to
                        Demystify the Truckload Rating Process</h5>
                    <p className="rd-text">A new invention is out on the market: a supply chain crystal ball, which
                        shippers can look
                        into and see freight demand, capacity, and rates of the future … ah, if only! Seeking out
                        the best freight rates is often easier said than done. Everything from market volatility to
                        fluctuating demand ....<a
                            className="read-external-article"
                            target="_blank"
                            rel="noreferrer"
                            href='https://farelanes.com/farelanes-report//'>
                            Read
                            article

                        </a></p>

                    <h5>Real-Time Rating: Take the Guesswork out of Freight Pricing</h5>

                    <p className="rd-text">If someone offered you the choice to make a decision either based on
                        real-time,
                        current data or based
                        on information that’s two weeks old, it seems like a no brainer to pick the former. Yet in the
                        world
                        of freight pricing, shippers too ....<a
                            className="read-external-article"
                            target="_blank"
                            rel="noreferrer"
                            href='https://farelanes.com/farelanes-report/'>
                            Read
                            article

                        </a></p>
                </div>
                <br/>
                <div
                    className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-1 border-bottom"></div>
                <br/>
                {/*<img className="popup-logo" src={TwitterLogo} width={48} height={48} />*/}
                <br/>
                <br/>
            </div>
        </Fragment>
);

export default Dashboard;
