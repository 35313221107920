import React from "react";
import {PageFooterHyperlink} from "./page-footer-hyperlink";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {DropdownItem} from "reactstrap";
import {useAuth0} from "@auth0/auth0-react";
import {Auth0Component} from "./Auth0Component";

export const PageFooter = () => {

    const {
        user,
        isAuthenticated,
        getAccessTokenSilently,
        loginWithRedirect,
        logout,
    } = useAuth0();


    const logoutWithRedirect = () =>
        logout({
            logoutParams: {
                returnTo: window.location.origin,
            }
        });

    const handleLogin = async () => {
        await loginWithRedirect({
            appState: {
                returnTo: "/dashboard",
            },
            authorizationParams: {
                prompt: "login",
                screen_hint: "signup",
            },
        });
    };

    return (
        <footer className=" fixed-bottom footer-color footer-style">
            <div className="footer-border ">
            <span className="footer-text">
              <span className="footer-style">© Copyright 2023, 2024 Farelanes LLC. All Rights Reserved.</span>
                {isAuthenticated && (
                    <span className="footer-right"><span
                        className="footer-normal-text">FL-V-2.0.7&nbsp;|&nbsp;{user.email}&nbsp;|</span>&nbsp;<span
                        className="footer-normal-text">&nbsp;<a
                        className="faq-text"
                        target="_blank"
                        rel="noreferrer"
                        href='https://farelanes.com/frequently-asked-questions/'>
    faq

</a>&nbsp;|</span>&nbsp;<span><button
                        className="" onClick={() => logoutWithRedirect()}>Logout</button></span></span>
                )}
            </span>
            </div>
        </footer>
    )
};
